import React from "react"
import styled from "styled-components"
import { Title } from "@bw/bits"
import { useTranslation } from "react-i18next"

const ComingSoon = ({ date }) => {
  const { t } = useTranslation()
  return (
    <NotMinted>
      <BigTitle>{t("collection.detail.comingSoon")}</BigTitle>
      <Title
        title={t("collection.detail.mintingDate")}
        subtitle={date}
        direction="row"
        justify="center"
      />
    </NotMinted>
  )
}

const BigTitle = styled.h2`
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #04062c;
`

const NotMinted = styled.div`
  margin: 0 auto;
  text-align: center;
`

export default ComingSoon
