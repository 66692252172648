import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { breakpoints } from "../../../theme"
import { Formik, Form, Field } from "formik"
import * as yup from "yup"
import { Search } from "@bw/icons"
import { Input, FormField } from "@bw/forms"
import { DropButtons, Spacer } from "@bw/bits"

const SearchBar = ({ setFilter }) => {
  const { t } = useTranslation()
  const searchSchema = yup.object({
    search: yup.string(),
    min_price: yup.number().min(0.01, t("user.list.priceIsToLow")),
    max_price: yup.number(),
    sortedBy: yup.string(),
    trade: yup.string(),
  })
  let timer = null
  return (
    <Formik
      initialValues={{
        search: "",
        min_price: "",
        max_price: "",
        sortedBy: "listed_at_desc",
        trade: "listed",
      }}
      validationSchema={searchSchema}
      onSubmit={values => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          setFilter(filter => ({ ...filter, ...values }))
        }, 500)
      }}
    >
      {({ submitForm, handleChange, setFieldValue }) => (
        <Form data-cy="search-form">
          <Box align="start">
            <Spacer direction="row">
              <div style={{ width: "320px" }}>
                <FormField name="search">
                  <Field
                    name="search"
                    icon={<Search />}
                    margin="0"
                    type="text"
                    placeholder={t("search")}
                    component={Input}
                    onChange={e => {
                      handleChange(e)
                      submitForm()
                    }}
                  />
                </FormField>
              </div>
              <div style={{ width: "120px" }}>
                <FormField name="min_price">
                  <Field
                    name="min_price"
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="Min price"
                    component={Input}
                    onChange={e => {
                      handleChange(e)
                      submitForm()
                    }}
                  />
                </FormField>
              </div>
              <div style={{ width: "120px" }}>
                <FormField name="max_price">
                  <Field
                    name="max_price"
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="Max price"
                    component={Input}
                    onChange={e => {
                      handleChange(e)
                      submitForm()
                    }}
                  />
                </FormField>
              </div>
              <FormField name="sortedBy">
                <Field
                  name="sortedBy"
                  subtitle={t("sortedBy.subtitle")}
                  component={DropButtons}
                  multiple={false}
                  onChange={x => {
                    setFieldValue("sortedBy", x.find(y => y.active).value)
                    submitForm()
                  }}
                  categories={[
                    {
                      active: true,
                      value: "listed_at_desc",
                      name: t("sortedBy.recent"),
                    },
                    {
                      active: false,
                      value: "listed_at_asc",
                      name: t("sortedBy.oldest"),
                    },
                    {
                      active: false,
                      value: "price_desc",
                      name: t("sortedBy.htl"),
                    },
                    {
                      active: false,
                      value: "price_asc",
                      name: t("sortedBy.lth"),
                    },
                  ]}
                />
              </FormField>
              <FormField name="trade">
                <Field
                  name="trade"
                  label="Selling"
                  subtitle={t("sortedBy.trade")}
                  component={DropButtons}
                  multiple={false}
                  onChange={x => {
                    setFieldValue("trade", x.find(y => y.active).value)
                    submitForm()
                  }}
                  categories={[
                    {
                      active: true,
                      value: "listed",
                      name: t("listed"),
                    },
                    {
                      active: false,
                      value: "not_listed",
                      name: t("unlisted"),
                    },
                  ]}
                />
              </FormField>
            </Spacer>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const Box = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;

  @media (min-width: ${breakpoints.medium}px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export default SearchBar
