import React from "react"
import { useLocalization } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"
import { useNfts } from "@contexts/nft"
import { graphql } from "gatsby"
import {
  DropButtons,
  Avatar,
  Image,
  Banner,
  Section,
  Spacer,
  Loader,
} from "@bw/bits"
import { breakpoints } from "../../theme"
import { Layout } from "@bw/layouts"
import { NftTeaser } from "@bw/partials"
import { getNftsInCollection, getCollection } from "@actions/collection"
import styled from "styled-components"
import Header from "./components/header.jsx"
import SearchBar from "./components/searchBar.jsx"
import ComingSoon from "./components/comingSoon.jsx"
import { useQuery } from "react-query"
import { InfiniteScroll } from "grommet"
import { LAMPORT_MULTIPLIER } from "../../utils/oyster/common/src/index.tsx"

const initialFilter = {
  attributes: [],
  search: "",
  min_price: "",
  max_price: "",
  sortedBy: "listed_at_desc",
  trade: "listed",
}

const CollectionTemplate = ({ data, location, pageContext }) => {
  const { locale } = pageContext
  const { localizedPath, defaultLang } = useLocalization()
  const { t } = useTranslation()
  const { collection } = data
  const [listedNfts] = useNfts()

  const queryKey = ["likes", collection.address]
  const { data: collectionData } = useQuery(queryKey, () =>
    getCollection(collection.address)
  )

  const [filter, setFilter] = React.useState(initialFilter)

  // get the prefiltered trait_type and value
  // this comes by clicking on a trait_type on the
  // NFT detail.
  React.useEffect(() => {
    if (!location.state) {
      return false
    }
    const { trait_type, value } = location.state
    if (trait_type && value) {
      const newFilter = { ...filter }
      newFilter.attributes[trait_type] = value
      setFilter(newFilter)
    }
  }, [location, filter])
  const date = new Date(collection.minted_at)
  const isFutureMint = date > new Date()
  const formatedDate = date.toLocaleDateString(locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  const nftsQuery = useQuery(
    ["nfts", collection.address, listedNfts.listed],
    () => {
      return getNftsInCollection(collection.address).then(response => {
        // find listed items
        const nfts = response.tokens.reduce(
          (acc, nft) => {
            const listedNft = listedNfts.listed.find(
              listed => listed.nft === nft.address
            )

            if (listedNft) {
              acc.listed.push({
                ...nft,
                price: listedNft.price,
                listed_at: listedNft.listed_at,
              })
            } else {
              acc.unlisted.push(nft)
            }

            return acc
          },
          { listed: [], unlisted: [] }
        )

        return {
          ...response,
          nfts,
        }
      })
    }
  )

  /**
   * Filter the NFTs
   */
  const nfts = React.useMemo(() => {
    if (!nftsQuery?.data?.nfts) {
      return []
    }

    let nfts =
      filter.trade === "listed"
        ? nftsQuery.data.nfts.listed
        : nftsQuery.data.nfts.unlisted

    // filter by attributes
    const attributes = Object.keys(filter.attributes)
    if (attributes.length > 0) {
      nfts = nfts.filter(nft => {
        for (let attribute of attributes) {
          const trait = nft.attributes.find(
            attr => attr.trait_type === attribute
          )

          if (!trait || !filter.attributes[attribute].includes(trait.value)) {
            return false
          }
        }

        return true
      })
    }

    if (filter.trade === "unlisted") {
      // we return at this point, there is no other possibilty of
      // filtering or sorting
      return nfts.unlisted
    }

    /**
     * Filtering
     */
    if (filter.search.length > 2) {
      nfts = nfts.filter(nft =>
        nft.name.toLowerCase().includes(filter.search.toLowerCase())
      )
    }

    if (filter.min_price) {
      nfts = nfts.filter(
        nft => nft.price >= filter.min_price * LAMPORT_MULTIPLIER
      )
    }

    if (filter.max_price) {
      nfts = nfts.filter(
        nft => nft.price <= filter.max_price * LAMPORT_MULTIPLIER
      )
    }

    /**
     * Sorting
     */
    switch (filter.sortedBy) {
      case "price_desc":
        nfts.sort((a, b) => b.price - a.price)
        break

      case "price_asc":
        nfts.sort((a, b) => a.price - b.price)
        break

      case "listed_at_asc":
        nfts.sort((a, b) => a.listed_at - b.listed_at)
        break

      case "listed_at_desc":
      default:
        nfts.sort((a, b) => b.listed_at - a.listed_at)
    }

    return nfts
  }, [filter, nftsQuery])

  const floorPrice = React.useMemo(() => {
    if (nftsQuery.isSuccess) {
      const nfts = [...nftsQuery.data.nfts.listed]
      if (nfts.length === 0) {
        return "-"
      }
      nfts.sort((a, b) => a.price - b.price)
      return nfts[0].price / LAMPORT_MULTIPLIER + " SOL"
    } else {
      return "-"
    }
  }, [nftsQuery.isSuccess, nftsQuery.data])

  /**
   * get the available attributes
   */
  const attributes = React.useMemo(() => {
    if (
      !nftsQuery?.data?.tokens ||
      process.env.GATSBY_FALLBACK_COLLECTION_ADDRESS === collection.address
    ) {
      return []
    }

    return nftsQuery.data.tokens.reduce((acc, token) => {
      const { attributes } = token
      for (let i = 0; i < attributes.length; i++) {
        if (typeof acc[attributes[i].trait_type] === "undefined") {
          acc[attributes[i].trait_type] = []
        }

        if (!acc[attributes[i].trait_type].includes(attributes[i].value)) {
          acc[attributes[i].trait_type].push(attributes[i].value)
        }
      }
      return acc
    }, [])
  }, [nftsQuery, collection.address])

  const avatar = <Avatar image={collection.icon_url} alt={collection.name} />
  collection.likes_count = null
  return (
    <Layout {...{ pageContext }}>
      <Section containerSize="large">
        <Spacer>
          <Banner image={collection.cover_url} />
          <Header
            collection={{ ...collection, ...collectionData }}
            listedItems={
              nftsQuery.isSuccess ? nftsQuery.data.nfts.listed.length : "-"
            }
            totalItems={
              nftsQuery.isSuccess ? nftsQuery.data.tokens.length : "-"
            }
            floorPrice={floorPrice}
          />
        </Spacer>
      </Section>
      {isFutureMint ? (
        <Section>
          <ComingSoon date={formatedDate} />
        </Section>
      ) : (
        <>
          <Section containerSize="large">
            <SearchBar {...{ setFilter }} />
          </Section>

          <Section containerSize="large">
            {nftsQuery.isLoading ? (
              <Loader
                messages={[
                  t("loading.yourNft"),
                  t("loading.itTakesLongerThanExpected"),
                ]}
                treshold={20}
              />
            ) : nftsQuery.status === "error" ? (
              <div>Error</div>
            ) : (
              <MainGrid>
                {Object.keys(attributes).length > 0 && (
                  <div>
                    <Spacer>
                      {Object.keys(attributes).map(attribute => {
                        return (
                          <DropButtons
                            key={attribute}
                            onChange={value => {
                              const selection = value
                                .filter(v => v.active)
                                .map(v => v.name)
                              const newFilter = { ...filter }

                              if (selection.length > 0) {
                                newFilter.attributes[attribute] = selection
                              } else {
                                delete newFilter.attributes[attribute]
                              }
                              setFilter(newFilter)
                            }}
                            label={attribute}
                            categories={attributes[attribute].map(value => ({
                              active: false,
                              name: value,
                            }))}
                          />
                        )
                      })}
                    </Spacer>
                  </div>
                )}
                {nfts.length === 0 &&
                  JSON.stringify(filter) !== JSON.stringify(initialFilter) && (
                    <NoResults>{t("noResults")}</NoResults>
                  )}
                {nfts.length >= 0 && (
                  <NftGrid>
                    <InfiniteScroll items={nfts} step={9}>
                      {item => (
                        <NftTeaser
                          key={item.address}
                          to={localizedPath({
                            defaultLang,
                            locale,
                            path: `/nft/${item.address}/`,
                          })}
                          state={{ from: location.pathname }}
                          name={item.name}
                          likes={item.likes_count}
                          artist={collection.name}
                          price={item.price}
                          image={
                            <Image
                              type="NFT"
                              avatar={avatar}
                              image={`${process.env.GATSBY_API_URL}/images/${item.image}`}
                            />
                          }
                        />
                      )}
                    </InfiniteScroll>
                  </NftGrid>
                )}
              </MainGrid>
            )}
          </Section>
        </>
      )}
    </Layout>
  )
}

const NftGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${breakpoints.small}px) {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
`

const NoResults = styled.div`
  width: 100%;
  text-align: center;
`

const MainGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.small}px) {
    grid-template-columns: 160px 1fr;
    grid-gap: 90px;
  }

  ${NftGrid}:first-child {
    grid-column: 1 / -1;
  }

  ${NoResults}:first-child {
    grid-column: 1 / -1;
  }
`

export default CollectionTemplate

export const collectionQuery = graphql`
  query collectionQuery($id: String!) {
    collection(id: { eq: $id }) {
      name
      address
      cover_url
      icon_url
      description
      website
      instagram
      discord
      twitter
      telegram
      minted_at
      tags {
        title
        slug
      }
    }
  }
`
