import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Avatar, Title, Spacer, KeyValue } from "@bw/bits"
import { Modal, Share } from "@bw/partials"
import { breakpoints } from "../../../theme"
import {
  Like,
  Share as ShareIcon,
  Telegram,
  Discord,
  Instagram,
  Website,
  Twitter,
} from "@bw/icons"
import {
  likeCollection,
  unlikeCollection,
  getCollectionVolume,
} from "@actions/collection"
import { useUser } from "@contexts/user"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;

  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;

    > :nth-child(1),
    > :nth-child(2) {
      align-self: center;
    }

    > :nth-child(even) {
      justify-self: end;
    }
  }
`

const AvatarGrid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;

  @media (min-width: ${breakpoints.medium}px) {
    gap: 45px;
  }
`

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 23px;
  color: #04062c;
  opacity: 0.7;
`

const Tag = styled(Link)`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.7;
  color: #6080e9;
  &:visited {
    color: #6080e9;
  }
`
const IconeWrap = styled.div`
  height: 24px;
  display: flex;
  color: #04062c;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  small {
    opacity: 0.8;
    font-size: 14px;
  }

  @media (max-width: ${breakpoints.medium}px) {
    gap: 25px;
  }
`

const Header = ({ collection, listedItems, totalItems, floorPrice }) => {
  const { t } = useTranslation()
  const [user, dispatch] = useUser()
  const userLikeCollection = user.data.likes.includes(collection.address)
  const [likes, setLikes] = React.useState(null)
  const [show, setShow] = React.useState(false)

  const { isSuccess, data } = useQuery(["collection_volume"], () => {
    return getCollectionVolume(collection.address)
  })

  React.useEffect(() => {
    setLikes(collection.likes_count)
  }, [collection])

  return (
    <>
      <Grid>
        <AvatarGrid>
          <div>
            <Avatar
              image={collection.icon_url}
              size="large"
              dropshadow="without"
            />
          </div>
          <div>
            <div>
              <Title title={collection.name} />
              <Spacer direction="row" gap="large">
                <IconeWrap
                  role="button"
                  onClick={() => {
                    if (likes === null) {
                      return false
                    }
                    if (userLikeCollection) {
                      unlikeCollection(collection.address)
                        .then(data => {
                          if (!data.unliked) {
                            throw new Error()
                          }
                          toast(t("user.toastUnlikeCollectionSuccess"))
                          dispatch({
                            type: "UNLIKE_COLLECTION",
                            address: collection.address,
                          })
                          setLikes(likes - 1)
                        })
                        .catch(() => {
                          toast.error(t("user.toastUnlikeCollectionFail"))
                        })
                    } else {
                      likeCollection(collection.address)
                        .then(data => {
                          if (!data.liked) {
                            throw new Error()
                          }
                          toast(t("user.toastLikeCollectionSuccess"))
                          dispatch({
                            type: "LIKE_COLLECTION",
                            address: collection.address,
                          })
                          setLikes(likes + 1)
                        })
                        .catch(() => {
                          toast.error(t("user.toastLikeCollectionFail"))
                        })
                    }
                  }}
                >
                  <Like
                    strokeColor="#04062C"
                    fillColor={userLikeCollection ? "#04062C" : ""}
                  />
                  <small>{likes}</small>
                </IconeWrap>

                <IconeWrap
                  role="button"
                  onClick={() => {
                    setShow(true)
                  }}
                >
                  <ShareIcon />
                  {t("share")}
                </IconeWrap>
              </Spacer>
            </div>
          </div>
        </AvatarGrid>

        <div>
          <StatsGrid>
            <KeyValue
              size="large"
              indicator={t("collection.detail.listedItems")}
              variable={`${listedItems}/${totalItems}`}
            />
            <KeyValue
              size="large"
              indicator={t("collection.detail.floorPrice")}
              variable={floorPrice}
            />
            <KeyValue
              size="large"
              indicator="Volume"
              variable={isSuccess ? data.all_time + " SOL" : "-"}
            />
          </StatsGrid>
        </div>
        <div>
          {collection.description && (
            <Description>{collection.description}</Description>
          )}
          {collection.tags && (
            <Spacer direction="row" align="center">
              {collection.tags.map(tag => (
                <Tag
                  key={tag.slug}
                  to="/collections/"
                  state={{ tag: tag.slug }}
                >
                  #{tag.title}
                </Tag>
              ))}
            </Spacer>
          )}
        </div>
        <div>
          <Spacer direction="row" align="center">
            {collection.website && (
              <a href={collection.website} target="_blank" rel="noreferrer">
                <Website fillColor="#04062C" />
              </a>
            )}
            {collection.twitter && (
              <a href={collection.twitter} target="_blank" rel="noreferrer">
                <Twitter fillColor="#04062C" />
              </a>
            )}
            {collection.instagram && (
              <a href={collection.instagram} target="_blank" rel="noreferrer">
                <Instagram fillColor="#04062C" />
              </a>
            )}
            {collection.telegram && (
              <a href={collection.telegram} target="_blank" rel="noreferrer">
                <Telegram fillColor="#04062C" />
              </a>
            )}
            {collection.discord && (
              <a href={collection.discord} target="_blank" rel="noreferrer">
                <Discord fillColor="#04062C" />
              </a>
            )}
          </Spacer>
        </div>
      </Grid>
      <Modal state={[show, setShow]}>
        <Title title={t("collection.share")} />
        <Share
          row={true}
          quote={collection.name}
          pageURL={typeof window === "undefined" ? "#" : window.location.href}
        />
      </Modal>
    </>
  )
}

export default Header
